/* stylelint-disable */
/*------------------------------------*    #FONTS
\*------------------------------------*/
/* stylelint-disable value-keyword-case */
/* stylelint-enable value-keyword-case */
/*------------------------------------*    #COLOURS
\*------------------------------------*/
/*------------------------------------*    #ANIMATIONS AND TRANSITIONS
\*------------------------------------*/
/*------------------------------------*    #BREAKPOINTS
\*------------------------------------*/
/*------------------------------------*    #Z-INDEXES
\*------------------------------------*/
/*doc
---
title: Colours
name: colours
category: colours
---

<ul class="styleguide-colours cf">
    <li class="styleguide-colours__item">
        <div class="styleguide-colours__colour -primary"></div>
        <div class="styleguide-colours__name">#8b5cdd - primary</div>
    </li>
    <li class="styleguide-colours__item">
        <div class="styleguide-colours__colour -primary-dark"></div>
        <div class="styleguide-colours__name">#3e3750 - primary-dark</div>
    </li>
    <li class="styleguide-colours__item">
        <div class="styleguide-colours__colour -primary-light"></div>
        <div class="styleguide-colours__name">#d1bef1 - primary-light</div>
    </li>
</ul>
<br><br>
<h4>Greys</h4>
<ul class="styleguide-colours cf">
    <li class="styleguide-colours__item">
        <div class="styleguide-colours__colour -pastel"></div>
        <div class="styleguide-colours__name">#e3dfec - pastel</div>
    </li>
    <li class="styleguide-colours__item">
        <div class="styleguide-colours__colour -cloud"></div>
        <div class="styleguide-colours__name">#f2f1f1 - cloud</div>
    </li>
    <li class="styleguide-colours__item">
        <div class="styleguide-colours__colour -overcast"></div>
        <div class="styleguide-colours__name">#a0a0a0 - overcast</div>
    </li>
    <li class="styleguide-colours__item">
        <div class="styleguide-colours__colour -dolphin"></div>
        <div class="styleguide-colours__name">#6c6978 - dolphin</div>
    </li>
    <li class="styleguide-colours__item">
        <div class="styleguide-colours__colour -inkblot"></div>
        <div class="styleguide-colours__name">#2d283e - inkblot</div>
    </li>
    <li class="styleguide-colours__item">
        <div class="styleguide-colours__colour -nimbus"></div>
        <div class="styleguide-colours__name">#333333 - nimbus</div>
    </li>
</ul>

*/
.fade-up,
.footer-item,
.about-clients__item,
.company-intro__content,
.fade-up-image,
.service-column,
.fade-up-immediate {
  opacity: 1 !important;
  transform: none !important;
}

.fade-up *,
.footer-item *,
.about-clients__item *,
.company-intro__content *,
.fade-up-image *,
.service-column *,
.fade-up-immediate * {
  opacity: 1 !important;
}

.site-logo,
.site-nav__item,
.site-header__email,
.social-icons li {
  opacity: 1 !important;
}

.btn {
  color: inherit !important;
  border: 2px solid !important;
  box-shadow: none !important;
}

.hero__title,
.shapes__title {
  visibility: visible !important;
}

.grid-item--white .grid__body {
  color: black !important;
}

.scroller-page-nav__link span {
  transform: none !important;
}

.scroller-page-scroller__item,
.scroller-page-scroller__heading,
.scroller-page-scroller__summary {
  opacity: 1 !important;
  transform: none !important;
}

.scroller-page-scroller {
  margin-left: auto !important;
}

.grid-item--w2 .grid__content::before {
  background: #fff !important;
}

[ix-path] {
  display: none !important;
}

[data-bg="white"] {
  background: #fff;
}

[data-bg="dark"] {
  background: #3e3750;
  color: #fff;
}

[data-bg="primary"] {
  background: #8b5cdd;
  color: #fff;
}

[data-bg="nimbus"] {
  background: #333;
  color: #fff;
}

/* stylelint-enable */
