/* stylelint-disable */
@import 'lib/vars';

.fade-up,
.footer-item,
.about-clients__item,
.company-intro__content,
.fade-up-image,
.service-column,
.fade-up-immediate {
    opacity: 1 !important;
    transform: none !important;

    * {
        opacity: 1 !important;
    }
}

.site-logo,
.site-nav__item,
.site-header__email,
.social-icons li {
  opacity: 1 !important;
}

.btn {
  color: inherit !important;
  border: 2px solid !important;
  box-shadow: none !important;
}

.hero__title,
.shapes__title {
  visibility: visible !important;
}

.grid-item--white .grid__body {
  color: black !important;
}

.scroller-page-nav__link span {
  transform: none !important;
}

.scroller-page-scroller__item,
.scroller-page-scroller__heading,
.scroller-page-scroller__summary {
  opacity: 1 !important;
  transform: none !important;
}

.scroller-page-scroller {
  margin-left: auto !important;
}

.grid-item--w2 .grid__content::before {
  background: color(white) !important;
}

[ix-path] {
  display: none !important;
}

[data-bg="white"] {
  background: color(white);
}

[data-bg="dark"] {
    background: color(primary-dark);
    color: color(white);
}

[data-bg="primary"] {
    background: color(primary);
    color: color(white);
}

[data-bg="nimbus"] {
    background: color(nimbus);
    color: color(white);
}
/* stylelint-enable */
